export const MenuItems = [
    {
      title: 'Privacy Policy',
      path: 'https://paypalfeecalculator.net/page/privacy-policy/',
      cName: 'dropdown-link'
    },
    {
      title: 'Terms of Service',
      path: 'https://paypalfeecalculator.net/page/terms-and-service/',
      cName: 'dropdown-link'
    },
    {
      title: 'DMCA Policy',
      path: 'https://paypalfeecalculator.net/page/dmca/',
      cName: 'dropdown-link'
    },
    {
      title: 'Accessibility',
      path: 'https://paypalfeecalculator.net/page/accessibility/',
      cName: 'dropdown-link'
    },
    
    {
      title: 'Terms of Sale',
      path: 'https://paypalfeecalculator.net/page/terms-of-sale/',
      cName: 'dropdown-link'
    },
    {
      title: 'Subscription',
      path: 'https://paypalfeecalculator.net/page/subscription/',
      cName: 'dropdown-link'
    },
    {
      title: 'Trust',
      path: 'https://paypalfeecalculator.net/page/trust/',
      cName: 'dropdown-link'
    },
    {
      title: 'Help',
      path: 'https://paypalfeecalculator.net/page/help/',
      cName: 'dropdown-link'
    }
  ];
  