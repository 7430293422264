  export const FaqItem = [
    {
        question:'Q: How does PayPal calculate fees for international transactions',
        answer:"A: Fees vary based on the sender's country. Check PayPal's detailed fee structure for accurate information. "
    },
    {
        question:" Q: Are there fees for using PayPal Here in the UK ",
        answer:" A: Yes, PayPal Here has specific rates for in-person card and contactless payments in the UK."
    },
    {
      question:" Q: What is the Mass Payments feature on PayPal ",
      answer:" A: Mass Payments is a PayPal feature allowing users to organize and distribute payments to multiple recipients simultaneously."
  },
  {
    question:" Q: How does PayPal charge for micropayments ",
    answer:" A: Micropayments have specific fees for eligible businesses processing payments under £5.    "
},
{
  question:"  Q: What are the fees for using QR codes with PayPal",
  answer:"A: QR code transactions incur fixed fees based on the currency received and the transaction amount. "
},
{
  question:"  Q: Can I accept real-time bank transfers with PayPal",
  answer:" A: Yes, alternative payment method rates apply if you offer real-time bank transfers through a third-party provider."
},
{
  question:"  Q: How does the New Checkout Solution work on PayPal",
  answer:" A: Eligible businesses can use the New Checkout Solution to accept card payments through a streamlined process."
},
{
  question:" Q: Are there fees for using PayPal Website Payments Pro ",
  answer:" A: Yes, PayPal Website Payments Pro has specific rates for integrated online and in-person payments."
},
{
  question:"Q: What countries fall under PayPal's Europe I category for fees?  ",
  answer:" A: Countries like France, Germany, Italy, and the UK are part of PayPal's Europe I, each with its own fee structure."
},
{
  question:" Q: How can I optimize my transactions with PayPal?",
  answer:" A: Utilize the PayPal Fee Calculator, stay informed about country-specific fees, and make strategic decisions for transparent and efficient financial dealings."
}

  ]