import React from 'react';
import './App.css';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// import Home from './components/pages/Home';
import Navbar from './components/Navbar';
import Footer from './components/Footer';

import Home from './pages/Home';
// import { Footer } from './components/Footer1';
import { ClassNames } from '@emotion/react';

function App() {
  return (
    <div className='paypal'>
    <Router>
      <Navbar />
      <Routes>
        <Route path='/' exact element={<Home/>} />
        {/* <Route path='/edit-panel' exact element={<EditSection/>}/> */}
        {/* <Route path='/about-us' element = {<Aboutus/> }/> */}
        
      </Routes>
      {/* <EditSection/> */}
      <Footer/>

    </Router>
    </div>

  );
}

export default App;