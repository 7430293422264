import React from "react";
import './Home.css';
import { Rates } from "../editableItems/FeeRate";
import Button from '@mui/material/Button';
import { useState, useEffect } from 'react';
import FAQ from "./faq";
import { CurrencyTypes } from "../editableItems/CurrencyType";
import Description from "../editableItems/Description";





const Home = () => {

    const [amount, setAmount] = useState('');
    const [selectedCurrency, setSelectedCurrency] = useState('');
    const [selectedRate, setSelectedRate] = useState('');
    const [paypalFee, setPaypalFee] = useState(0);
    const [youWillReceive, setYouWillReceive] = useState(0);
    const [youShouldAskFor, setYouShouldAskFor] = useState(0);
    const [rateValarr, setRateValarr] = useState([]);

    const [currencySign, setCurrencySign] = useState('');
    // const [addcurrency, setAddcurrency] = useState(0);

    useEffect(() => {
        // Update the rateValarr when selectedCurrency changes
        console.log(selectedCurrency);
        if(selectedCurrency === 'Please choose one option'){
            setRateValarr([]);
            setSelectedRate("");
        }
        else if (selectedCurrency) {
            setRateValarr(Rates[selectedCurrency]);
            setSelectedRate("");
            // console.log(Rates[selectedCurrency])
        }
    }, [selectedCurrency]);

    const resetCacl = () =>{
        setAmount('');
        setSelectedCurrency('');
        setSelectedRate('');
        setPaypalFee(0);
        setYouWillReceive(0);
        setYouShouldAskFor(0);
        setRateValarr([]);
        setCurrencySign('');
    }

    const calculatePaypalFee = () => {
        // Check if amount, selectedCurrency, and selectedRate are valid
        if (amount && selectedCurrency && selectedRate) {
            const rate = parseFloat(selectedRate);
            const amountNum = parseFloat(amount);

            // console.log(amountNum,rate);
            // Define the regular expressions to extract information
            const percentageRegex = /([\d.]+)%/;
            const operatorRegex = /\s*([+])\s*/;
            const currencyRegex = /([₹£$€]+)/;
            const valueRegex = /(?:[0-9]+(?:\.[0-9]*)?|\.[0-9]+)/g;

            // console.log(rate, amount);

            // Extract information using regular expressions
            const percentageMatch = selectedRate.match(percentageRegex);
            const operatorMatch = selectedRate.match(operatorRegex);
            const currencyMatch = selectedRate.match(currencyRegex);
            const valueMatch = selectedRate.match(valueRegex);

            // console.log(percentageMatch,operatorMatch,currencyMatch,valueMatch);
            // console.log(typeof(percentageMatch),typeof(operatorMatch),typeof(currencyMatch),typeof(valueMatch));

            // Extracted values
            const percentage = percentageMatch ? parseFloat(percentageMatch[1]) : null;
            const operator = operatorMatch ? operatorMatch[1] : null;
            const currencySymbol = currencyMatch ? currencyMatch[1] : null;
            const valueAfterCurrency = valueMatch && valueMatch.length >= 2 ? parseFloat(valueMatch[1]) : null;

            //  console.log(percentage,operator,currencySymbol, valueAfterCurrency);

            // console.log(typeof (percentage), typeof (operator), typeof (currencySymbol), typeof (valueAfterCurrency));


            // Calculate PayPal fee
            const fee = ((amountNum * rate) / 100) + valueAfterCurrency;

            // Calculate amount you will receive
            const receiveAmount = amountNum - fee;

            // Calculate amount you should ask for
            const askForAmount = (amountNum+valueAfterCurrency)/(1-(rate/100));


            // Update state values
            setPaypalFee(fee);
            setYouWillReceive(receiveAmount);
            setYouShouldAskFor(askForAmount);

            // setAddcurrency(valueAfterCurrency);
            setCurrencySign(currencySymbol);
        }
    };



    const handleCurrencyType = (value) => {
        setSelectedCurrency(value);
        // setRateValarr(Rates[value])
        // setSelectedRate("");

        // console.log(Rates[value])
    }

    useEffect(() => {
        // Call your calculation function whenever necessary inputs change
        calculatePaypalFee();
    }, [amount, selectedCurrency, selectedRate]);


    return (
        <>
            <div className="home">
                <h1 className="tophead">Paypal Fee Calculator</h1>
                <h2 className="subtophead">The  Best   PapPal  Fee   Calculator   is   Now   Just    a   click   Away</h2>
                <div className="inputdivision">
                <div>
                <h2 className="headinput">Enter amount</h2>
                    <div className="input-container">
                    <span className="currency-sign" style={{letterSpacing:'1.7px',fontSize:'20px'}}>{currencySign ? currencySign : '$'}</span>
                        <input
                            type="text"
                            className="styled-input"
                            placeholder="Enter amount"

                            value={amount}
                            onChange={(e) => setAmount(e.target.value)}
                        ></input>

                    </div>
                </div>
                

                    {/* <input type="text" className="styled-input" placeholder="Enter text" /> */}
                    <div>
                        <h2 className="headinput">Country or Region (Currency)</h2>

                        <select
                            className="selectContainer"
                            value={selectedCurrency}
                            onChange={(e) => handleCurrencyType(e.target.value)}
                        >
                            <option className="selectOption">Please choose one option</option>

                            {CurrencyTypes.map((val, index) => {
                                return (
                                    <option className="selectOption" value={val.value} key={index}>{val.key}</option>
                                )
                            })}
                        </select>


                    </div>
                    <div>
                        <h2 className="headinput">Fee rate</h2>
                        <select
                            className="selectContainer"
                            value={selectedRate}
                            onChange={(e) => setSelectedRate(e.target.value)}
                        >
                            <option>Please choose one option</option>

                            {rateValarr.map((val, index) => {
                                return (
                                    <option value={val.rate} key={index}>{val.rate}</option>
                                )
                            })}
                        </select>




                    </div>
                </div>
                <div className="buttonDiv">
                    <Button
                        variant="contained"
                        className="styleButton"
                        // href="#contained-buttons" 
                        onClick={resetCacl}

                        sx={{ width: '140px', height: '40px', marginRight: '10px', borderRadius: '20px', fontSize: '16px', backgroundColor:'#82BC3B' ,
                        '&:hover': {
                            backgroundColor: '#4CAF50',  // Change this to the desired hover color
                        },
                        margin:'10px'
                        }}>
                        reset
                    </Button>

                    <Button
                        variant="contained"
                        className="styleButton"
                        // href="#contained-buttons" 
                        onClick={calculatePaypalFee}
                        sx={{ width: '200px', height: '40px', marginRight: '10px', borderRadius: '20px', fontSize: '16px', backgroundColor:'#82BC3B' ,
                        '&:hover': {
                            backgroundColor: '#4CAF50',  // Change this to the desired hover color
                        },
                        margin:'10px'
                        }}
                        >
                        calculate
                    </Button>
                </div>

            </div>
            <div className="resultComp">
                <h2 className="tophead">PayPal Fee Calculator Result</h2>

                <div className="outputDiv">

                    <div className="outputSec" id="fixpose">Paypal fee <br></br> <div className="output">{currencySign ? currencySign : '$'}{paypalFee.toFixed(2)}</div></div>
                    <div className="outputSec" id="fixpose">You will receive <br></br> <div className="output">{currencySign ? currencySign : '$'}{youWillReceive.toFixed(2)}</div><br></br><text className="outputSpan" >{amount ? `If you invoice for ${currencySign}${amount}` : ''}</text></div>
                    <div className="outputSec" id="fixpose">You should ask for <br></br> <div className="output">{currencySign ? currencySign : '$'}{youShouldAskFor.toFixed(2)}</div><br></br><text className="outputSpan" >{amount ? `If you need ${currencySign}${amount}` : ''}</text></div>
                </div>
            </div>

            <div className="descriptionDiv">
                <Description/>
            </div>

            <FAQ />

        </>
    );
}
export default Home;