// export const Rates = [
//     {
//         rate : '3.49% + $0.49 (Invoicing, PayPal Checkout, Venmo)'
//     },
//     {
//        rate: '2.99% + $0.49 (Credit and Debit Cards)' 
//     },
//     {
//         rate: '2.89% + $0.49 (Goods and Services, Donations)' 
//      },
//      {
//         rate: '1.99% + $0.49 (Charity)' 
//      },
//      {
//         rate: '4.99% + $0.09 (Micropayment)' 
//      },
//      {
//         rate: '3.5% (PayPal Guest Checkout - American Express Payments)' 
//      },
//      {
//         rate: ' 1.9% + $0.1 (QR code - $10.01 and above)' 
//      },
//      {
//         rate: '2.4% + $0.05 (QR code - $10 and below)' 
//      }

// ]

// export const InternationalUSD = [
//    {
//       rate : '4.99% + $0.49 (Invoicing, PayPal Checkout, Venmo)'
//    },
//    {
//       rate : '4.49% + $0.49 (Credit and Debit Cards)'
//    },
//    {
//       rate : '4.39% + $0.49 (Goods and Services, Donations)'
//    },
//    {
//       rate : '3.49% + $0.49 (Charity)'
//    },
//    {
//       rate : '6.49% + $0.09 (Micropayment)'
//    }
// ]

// export const DomesticCAD = [
//    {
//       rate : '2.9% + $0.3'
//    },
//    {
//       rate : '1.6% + $0.3 (Charitable Organizations)'
//    },
//    {
//       rate : '2.9% + $0.3 (PayPal Payments Pro)'
//    },
//    {
//       rate : '3.1% + $0.3 (Virtual Terminal)'
//    },
//    {
//       rate : '5% + $0.05 (Micropayment)'
//    }
// ]

// export const UsCAD = [
//    {
//       rate : '3.7% + $0.3'
//    },
//    {
//       rate : '1.6% + $0.3 (Charitable Organizations)'
//    },
//    {
//       rate : '3.7% + $0.3 (PayPal Payments Pro)'
//    },
//    {
//       rate : '3.9% + $0.3 (Virtual Terminal)'
//    },
//    {
//       rate : '5% + $0.05 (Micropayment)'
//    }
// ]

// export const OutSidecanadaOrUsCAD = [
//    {
//       rate : '3.9% + $0.3'
//    },
//    {
//       rate : '2.6% + $0.3 (Charitable Organizations)'
//    },
//    {
//       rate : '3.9% + $0.3 (PayPal Payments Pro)'
//    },
//    {
//       rate : '4.1% + $0.3 (Virtual Terminal)'
//    },
//    {
//       rate : '6% + $0.05 (Micropayment)'
//    }
// ]

// export const AmericanExpressCAD = [
//    {
//       rate : '3.5%'
//    }
// ]

// export const IndianLocalINR = [
//    {
//       rate : '2.5% + ₹3'
//    }
// ]

// export const IndianInternationalINR = [
//    {
//       rate : '4.4% + ₹3 (Up to $3000 monthly sales)'
//    },
//    {
//       rate : '3.9% + ₹3 ($3000+ to $10,000 monthly sales)'
//    },
//    {
//       rate : '3.7% + ₹3 ($10,000+ to $100,000 monthly sales)'
//    },
//    {
//       rate : '3.4% + ₹3 ($100,000+ monthly sales)'
//    }

// ]

// export const UnitedKingdomGBP = [
//    {
//       rate : '2.9% + £0.3 (Domestic)'
//    },
//    {
//       rate : '3.4% + £0.3 (From Europe I, Northern Europe)'
//    },
//    {
//       rate : '4.9% + £0.3 (From Canada, USA, Europe II, rest of the world)'
//    },
//    {
//       rate : '1.4% + £0.2 (Charity)'
//    },
//    {
//       rate : '5% + £0.05 (Micropayment)'
//    }
// ]




export const Rates = {
   UnitedStateDomesticUSD : [
      {
          rate : '3.49% + $0.49 (Invoicing, PayPal Checkout, Venmo)'
      },
      {
         rate: '2.99% + $0.49 (Credit and Debit Cards)' 
      },
      {
          rate: '2.89% + $0.49 (Goods and Services, Donations)' 
       },
       {
          rate: '1.99% + $0.49 (Charity)' 
       },
       {
          rate: '4.99% + $0.09 (Micropayment)' 
       },
       {
          rate: '3.5% (PayPal Guest Checkout - American Express Payments)' 
       },
       {
          rate: ' 1.9% + $0.1 (QR code - $10.01 and above)' 
       },
       {
          rate: '2.4% + $0.05 (QR code - $10 and below)' 
       }
   
   ],
   InternationalUSD : [
      {
         rate : '4.99% + $0.49 (Invoicing, PayPal Checkout, Venmo)'
      },
      {
         rate : '4.49% + $0.49 (Credit and Debit Cards)'
      },
      {
         rate : '4.39% + $0.49 (Goods and Services, Donations)'
      },
      {
         rate : '3.49% + $0.49 (Charity)'
      },
      {
         rate : '6.49% + $0.09 (Micropayment)'
      }
    ],
    DomesticCAD : [
      {
         rate : '2.9% + $0.3'
      },
      {
         rate : '1.6% + $0.3 (Charitable Organizations)'
      },
      {
         rate : '2.9% + $0.3 (PayPal Payments Pro)'
      },
      {
         rate : '3.1% + $0.3 (Virtual Terminal)'
      },
      {
         rate : '5% + $0.05 (Micropayment)'
      }
    ],

    UsCAD : [
      {
         rate : '3.7% + $0.3'
      },
      {
         rate : '1.6% + $0.3 (Charitable Organizations)'
      },
      {
         rate : '3.7% + $0.3 (PayPal Payments Pro)'
      },
      {
         rate : '3.9% + $0.3 (Virtual Terminal)'
      },
      {
         rate : '5% + $0.05 (Micropayment)'
      }
    ],
    OutSidecanadaOrUsCAD : [
      {
         rate : '3.9% + $0.3'
      },
      {
         rate : '2.6% + $0.3 (Charitable Organizations)'
      },
      {
         rate : '3.9% + $0.3 (PayPal Payments Pro)'
      },
      {
         rate : '4.1% + $0.3 (Virtual Terminal)'
      },
      {
         rate : '6% + $0.05 (Micropayment)'
      }
    ],
    AmericanExpressCAD : [
      {
         rate : '3.5%'
      }
    ],

    IndianLocalINR : [
      {
         rate : '2.5% + ₹3'
      }
    ],

    IndianInternationalINR : [
      {
         rate : '4.4% + ₹3 (Up to $3000 monthly sales)'
      },
      {
         rate : '3.9% + ₹3 ($3000+ to $10,000 monthly sales)'
      },
      {
         rate : '3.7% + ₹3 ($10,000+ to $100,000 monthly sales)'
      },
      {
         rate : '3.4% + ₹3 ($100,000+ monthly sales)'
      }
    
    ],
    UnitedKingdomGBP : [
      {
         rate : '2.9% + £0.3 (Domestic)'
      },
      {
         rate : '3.4% + £0.3 (From Europe I, Northern Europe)'
      },
      {
         rate : '4.9% + £0.3 (From Canada, USA, Europe II, rest of the world)'
      },
      {
         rate : '1.4% + £0.2 (Charity)'
      },
      {
         rate : '5% + £0.05 (Micropayment)'
      }
    ]
}










