export const CurrencyTypes = [
    {
      key : 'United States - Domestic (USD)',
      value: 'UnitedStateDomesticUSD'
    },


    {
        key : 'United States - International (USD)',
        value: 'InternationalUSD'
    },
    {
        key : 'Canada - Domestic (CAD)',
        value : 'DomesticCAD'
    },
    {
        key : 'Canada - From U.S. (CAD)',
        value: 'UsCAD'
    },
    {
        key : 'Canada - From Outside Canada or U.S. (CAD)',
        value: 'OutSidecanadaOrUsCAD'
    },
    {
        key : 'Canada - American Express (CAD)',
        value: 'AmericanExpressCAD'
    },
    {
        key : 'India - Local (INR)',
        value: 'IndianLocalINR'
    },
    {
        key : 'India - International (INR)',
        value:'IndianInternationalINR'
    },
    {
        key : 'United Kingdom (GBP)',
        value:'UnitedKingdomGBP'
    }
  ];
