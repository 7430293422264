import React from 'react';
// import { Link } from 'react-router-dom';
import './Footer.css';




function Footer() {

    return (
        <>
            <div className='footer'>
                <div class="container-dv9">
                    <div class="container-6cq">
                        <hr class="block-kme" />
                    </div>
                    <div class="container-ow4">
                        <div class="wrapper-tnm">
                            <div class="column-y98 column-4ee">
                                <div class="container-7av">
                                    <figure class="block-zdb block-ygh">
                                        <a href="https://paypalfeecalculator.net">
                                            <img width="300" height="300" class="image-cwx"
                                                src="https://paypalfeecalculator.net/page/wp-content/uploads/2024/01/new-paypal-300x300.png" />
                                        </a>
                                    </figure>
                                </div>
                            </div>
                            <div class="column-y98 column-iq1">
                                <div class="container-v1n">
                                    <p class="gb-headline-xdq">
                                        <br />
                                        Ever wondered about the mysterious world of PayPal fees? Worry not, as our PayPal Fee
                                        Calculator is here to simplify your life! Imagine a tool that takes the confusion out of
                                        transactions, allowing you to calculate PayPal charges effortlessly. No more scratching your
                                        head trying to figure out who should bear the brunt of those fees! Our user-friendly
                                        calculator is your ticket to swift calculations with just a click.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="container-gop">
                            <div>
                                <div class="container-ym6">
                                    <a class="button-qns button-hwd" href="https://paypalfeecalculator.net/page/contact-us/" target="_blank">
                                        <strong>
                                            Contact Us
                                        </strong>
                                    </a>
                                    <a class="button-qns button-9ii" href="https://paypalfeecalculator.net/page/work-with-us/" target="_blank">
                                        <strong>
                                            Work With us
                                        </strong>
                                    </a>
                                    <a class="button-qns button-9nm" href="https://paypalfeecalculator.net/page/advertise/" target="_blank">
                                        <strong>
                                            Advertise
                                        </strong>
                                    </a>
                                    <a class="button-qns button-5na" href="https://paypalfeecalculator.net/page/guest-posting/" target="_blank">
                                        <strong>
                                            Guest Posting
                                        </strong>
                                    </a>
                                    <a class="button-qns button-gq5" href="https://paypalfeecalculator.net/page/frequently-asked-questions/" target="_blank">
                                        <strong>
                                            FAQ
                                        </strong>
                                    </a>
                                    <a class="button-qns button-jxw" href="https://paypalfeecalculator.net/page/sitemap_index.xml" target="_blank">
                                        <strong>
                                            Site Map
                                        </strong>
                                    </a>
                                </div>
                            </div>
                            <div class="container-mdc">
                                <div>
                                    <p class="gb-headline-165">
                                        © 2024 by 
                                        <strong className='strongCopyright'>
                                            PayPal Fee Calculator
                                        </strong>
                                        : All rights reserved
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="container-oj1">
                        <hr class="block-kme" />
                    </div>
                </div>
            </div>
        </>
    );
}

export default Footer;
